import { Fragment, useEffect, useState, ReactNode } from 'react'

import { DialogType, IDialogInput } from '@/types/dialog.interface'
import style from '@/styles/Dialog.module.css'
import { NextPage } from 'next'

const styles = style as Record<
    'dialog' | 'content' | 'actions' |
    'overlay' | 'dialog-info' | 'submit-button' |
    'cancel-button' | 'processing' | 'dialog-close', string
>

const Dialog: NextPage<IDialogProps> = ({
    title, description, opened,
    dismissable, type, inputs,
    enableTogglingKeypresses,
    confirmButtonName,
    cancelButtonName,
    className, descriptionHTML,
    onClose, children
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(opened)
    description = description || ''

    useEffect(() => {
        setIsOpen(opened)

        opened
            ? document.body.classList.add('dialog-opened')
            : document.body.classList.remove('dialog-opened')

        const handleKeypress = (e: KeyboardEvent): void => {
            if (enableTogglingKeypresses) {
                if (e.key == 'Enter') {
                    handleClose(true, inputs)
                }

                if (e.key == 'Escape') {
                    handleClose(false, {})
                }

                document.addEventListener('keydown', handleKeypress)
            }
        }


        if (enableTogglingKeypresses) {
            return () => {
                document.removeEventListener('keydown', handleKeypress)
            }
        }
    }, [opened])

    const parseMarkdownString = (input: string): string => {
        const boldRegex = /\*\*(.*?)\*\*/g
        const italicRegex = /\*(.*?)\*/g
        const underlineRegex = /__(.*?)__/g

        const result = input
            .replaceAll(boldRegex, '<b>$1</b>')
            .replaceAll(italicRegex, '<em>$1</em>')
            .replaceAll(underlineRegex, '<u>$1</u>')

        return result
    }

    const parseMarkdown = (input: JSX.Element[]): JSX.Element => {
        const joinedString = input.map(element => element.props.children).join('')
        const result = parseMarkdownString(joinedString)

        return <a dangerouslySetInnerHTML={{ __html: result }} />
    }

    const descriptionLines = description.split('\n')

    const descriptionWithLineBreaks = descriptionLines
        .map((line, index) =>
            <Fragment key={index}>
                {parseMarkdown([<>{line}</>])}
                <br />
            </Fragment>
        )


    const getConfirmationButtons = (): JSX.Element => {
        switch (type) {
            case DialogType.INFO:
                return (
                    <a className={styles['submit-button']} onClick={(): any => handleClose(true, inputs)}>
                        {cancelButtonName || 'ОК'}
                    </a>
                )

            case DialogType.QUESTION:
                return (
                    <>
                        <a className={styles['submit-button']} onClick={(): any => handleClose(true, inputs)}>
                            {confirmButtonName || 'Да'}
                        </a>
                        <a className={styles['cancel-button']} onClick={(): any => handleClose(false, {})}>
                            {cancelButtonName || 'Нет'}
                        </a>
                    </>
                )

            case DialogType.SUBMIT:
                return (
                    <a className={styles['submit-button']} onClick={(): any => handleClose(true, inputs)}>
                        {confirmButtonName || 'Отправить'}
                    </a>
                )

            case DialogType.SUBMIT_WITH_CANCEL:
                return (
                    <>
                        <a className={styles['submit-button']} onClick={(): any => handleClose(true, inputs)}>
                            {confirmButtonName || 'Отправить'}
                        </a>

                        <a className={styles['cancel-button']} onClick={(): any => handleClose(false, {})}>
                            {cancelButtonName || 'Отмена'}
                        </a>
                    </>
                )

            case DialogType.NONE:
                return (
                    <></>
                )
        }
    }

    const handleClose = async (confirmed: boolean, inputs?: IDialogInput): Promise<void> => {
        if (onClose) {
            const [handlingResult] = await Promise.all([onClose(confirmed, inputs || {})])
            const toClose = handlingResult == undefined ? true : handlingResult

            if (toClose || !confirmed) {
                setIsOpen(false)
            }
        } else {
            setIsOpen(false)
        }
    }

    return (
        <>
            {isOpen && (
                <div className={`${styles.dialog}${className ? ' ' + className : ''}`}>
                    <div className={styles.content}>
                        <div className={styles['dialog-info']}>
                            <h2>
                                {title}
                                {/* {dismissable && <>
                                    {' '}<i
                                        className={`fas fa-plus ${styles['dialog-close']}`}
                                        onClick={(): void => handleClose(false, {})}
                                    />
                                </>} */}
                                {/* ^ dialog X button, will be worked on later ^ */}
                            </h2>

                            {
                                description ?
                                    <p>
                                        {descriptionHTML ? '' : descriptionWithLineBreaks}
                                    </p> : descriptionHTML
                                        ? <p dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
                                        : <></>
                            }
                        </div>

                        {children}

                        <div className={styles.actions}>
                            {getConfirmationButtons()}
                        </div>
                    </div>

                    <div className={styles.overlay} onClick={
                        dismissable ? (): any => handleClose(false, {}) : undefined
                    } />
                </div>
            )}
        </>
    )
}

export default Dialog

interface IDialogProps {
    title: string
    description?: string
    descriptionHTML?: string
    opened: boolean
    dismissable: boolean
    type: DialogType
    enableTogglingKeypresses?: boolean
    className?: string
    confirmButtonName?: string
    cancelButtonName?: string
    inputs?: IDialogInput

    /**
     * @param confirmed Whether the user sent the positive responce to the dialog.
     * @param dialogUserInput The inputs from the user (text fields, checkboxes, etc).
     * @returns Whether the dialog should be closed on pressing the dialog closing buttons.
     */
    onClose?: DialogCloseFunction
    children?: ReactNode
}

type DialogCloseFunction = (confirmed: boolean, dialogUserInput: IDialogInput) => boolean | Promise<boolean>
